import React from "react"
import Page from "../components/page";


const NotFoundPage = (props) => {
 
  const pageTitle="Not found";
  return (
    <Page location={props.location.href} pageTitle={pageTitle}>
    <h1>{pageTitle}</h1>
            Page not found.
    </Page>
    );
};
export default NotFoundPage;